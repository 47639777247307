import {
  ApproveOfferingReviewCommand,
  CancelOfferingReviewCommand,
  EntityInformation,
  GetCurrentUserCommand,
  GetCurrentUserOutput,
  GetOfferingCommand,
  GetOfferingReviewCommand,
  GetOfferingReviewCommandOutput,
  ListEntitiesCommand,
  ListOfferingReviewsCommand,
  Offering,
  OfferingReview,
  RejectOfferingReviewCommand,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import formStyles from '../Form.module.sass';
import {
  Box,
  Button,
  Container,
  Form,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  RadioGroup,
} from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import {
  BrandPrefixField,
  DeprecatedNameField,
  DocsCategoriesField,
  DoNotUseNameField,
  ExtraLongNameField,
  ExtraShortNameField,
  GeneralNotesField,
  LaunchStatusField,
  LocGuidanceRequiredField,
  LongNameField,
  MarketingCategoriesField,
  OfferingNameField,
  PrefixRequiredField,
  ResourceExamplesField,
  RipServiceIdField,
  ShortDescriptionField,
  ShortNameField,
  TypeField,
  LocalizationNotesField,
  LocalizationGuidanceField,
  LocalizationWordsField,
  ShortNameEntityIdField,
  LongNameEntityIdField,
  HomepageLinkField,
  HomepageTitleField,
  RegionsField,
  PromoteCategoriesField,
} from '../fields';
import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';
import { delocalizeRegionMap } from '../delocalizeRegionMap';
import { CreateEntitiesForm } from './CreateEntitiesForm';
import { Empty } from '../Empty';
import { Entable } from '../../Entable';

export const ViewReviewForm = (): ReactElement => {
  const [notice, setNotice] = useState<{
    title: string;
    body: string;
    callback?: () => void;
  }>();
  const { token } = useAuthState();
  const [doc, setDoc] = useState<OfferingReview | Offering>();
  const [oldOffering, setOldOffering] = useState<Offering>();
  const [oldOfferingQueried, setOldOfferingQueried] = useState(false);
  const [reviewQueried, setReviewQueried] = useState(false);
  const [offeringQueried, setOfferingQueried] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldOfferingId, setOldOfferingId] = useState<string>();
  const [badRejection, setBadRejection] = useState(false);
  const [entities, setEntities] = useState<EntityInformation[]>();
  const [entitiesQueried, setEntitiesQueried] = useState(false);
  const offeringReviewId = /Moniker\/drafts\/(.+?)\/?$/.exec(
    location.hash
  )?.[1];
  const rawOfferingId = /Moniker\/offerings\/(.+?)\/?$/.exec(
    location.hash
  )?.[1];
  const offeringId = rawOfferingId
    ? decodeURIComponent(rawOfferingId)
    : undefined;
  const [rejectionReason, setRejectionReason] = useState<string>();
  const [showEntityForm, setShowEntityForm] = useState(false);
  const [currentUser, setCurrentUser] = useState<GetCurrentUserOutput>();
  useEffect(
    () => setLoading((offeringQueried || reviewQueried) && !doc),
    [offeringQueried, reviewQueried, doc]
  );
  useEffect(() => {
    if (!oldOfferingId || oldOffering || oldOfferingQueried || !token) return;
    setOldOfferingQueried(true);
    callApi(new GetOfferingCommand({ offeringId: oldOfferingId }), token)
      .then((e) => {
        const o = e.offering;
        if (!o) throw 'API success but no offering returned';
        setOldOffering(o);
      })
      .catch((e) =>
        setNotice({ title: 'GetOffering call failed', body: String(e) })
      );
  }, [oldOfferingId, oldOffering, oldOfferingQueried]);
  useEffect(
    () =>
      setBadRejection(
        !(rejectionReason?.length && rejectionReason.length < 50)
      ),
    [rejectionReason]
  );
  useEffect(() => {
    if (doc || reviewQueried || !offeringReviewId || !token) return;
    setReviewQueried(true);
    void callApi(
      new GetOfferingReviewCommand({ offeringReviewId }),
      token
    ).then((res: GetOfferingReviewCommandOutput) => {
      setDoc(res.offeringReview);
      if (res.offeringReview.offeringReviewType === 'UPDATE')
        setOldOfferingId(res.offeringReview.offeringId);
    });
  }, [doc, reviewQueried, offeringReviewId]);
  useEffect(() => {
    const someOfferingId = offeringId ?? doc?.offeringId;
    if (!someOfferingId || entities || entitiesQueried || !token) return;
    setEntitiesQueried(true);
    void callApi(
      new ListEntitiesCommand({
        offeringId: someOfferingId,
        locale: 'ENGLISH',
        entityType: 'SERVICE_ENTITY',
      }),
      token
    )
      .then(({ entities }) => {
        console.log('Got entities', entities);
        setEntities(entities);
      })
      .catch((err) =>
        setNotice({ title: 'ListEntities call failed', body: String(err) })
      );
  }, [offeringId, doc?.offeringId, entities, entitiesQueried]);
  useEffect(() => {
    if (doc || offeringQueried || !offeringId || !token) return;
    setOfferingQueried(true);
    void callApi(new GetOfferingCommand({ offeringId }), token).then((res) => {
      setDoc(res.offering);
      callApi(new GetCurrentUserCommand({}), token)
        .then((res) => setCurrentUser(res))
        .catch((err) =>
          setNotice({
            title: 'Error calling GetCurrentUser',
            body: String(err),
          })
        );
    });
  }, [doc, offeringQueried, offeringId]);
  useEffect(() => {
    if (!token) return;

    if (!currentUser) {
      callApi(new GetCurrentUserCommand({}), token)
        .then((res) => {
          console.log('Current user fetched:', res); // Log the response
          setCurrentUser(res);
        })
        .catch((err) => {
          console.error('Error fetching current user:', err); // Log the error
          setNotice({
            title: 'Error calling GetCurrentUser',
            body: String(err),
          });
        });
    }
  }, [token]);
  const [drafts, setDrafts] = useState<OfferingReview[]>();
  const [draftsQueried, setDraftsQueried] = useState(false);
  useEffect(() => {
    if (drafts || draftsQueried || !offeringId || !token) return;
    setDraftsQueried(true);
    callApi(new ListOfferingReviewsCommand({ offeringId }), token)
      .then((e) => setDrafts(e.offeringReviews))
      .catch(apiFailed);
  }, [drafts, draftsQueried]);
  const apiFailed = (e: unknown) =>
    setNotice({ title: 'Failed', body: String(e) });
  const noticeCallback = () => {
    setNotice(undefined);
    notice?.callback?.();
  };
  const reviewPerms =
    doc && 'userPermissions' in doc ? doc.userPermissions : undefined;
  return (
    <Container>
      <Form
        variant='full-page'
        className={formStyles.Form}
        header={<Box variant='h2'>View {doc?.offeringName}</Box>}
        actions={
          offeringReviewId && doc ? (
            <SpaceBetween size='m' direction='horizontal'>
              {reviewPerms?.includes('CAN_UPDATE_ALL_EXCEPT_LOC_INFO') &&
                'offeringReviewStatus' in doc &&
                doc.offeringReviewStatus === 'REJECTED' && (
                  <Button
                    variant='normal'
                    disabled={loading}
                    href={`/#Moniker/drafts/${offeringReviewId}/edit`}
                  >
                    Edit draft
                  </Button>
                )}
              {'offeringReviewStatus' in doc && (
                <>
                  {reviewPerms?.includes('CAN_UPDATE_LOC_INFO') &&
                    doc.offeringReviewStatus === 'PENDING_LOC_REVIEW' && (
                      <Button
                        variant='normal'
                        disabled={loading}
                        href={`/#Moniker/drafts/${offeringReviewId}/edit-loc`}
                      >
                        Edit loc info
                      </Button>
                    )}
                  {reviewPerms?.includes('CAN_REVIEW') &&
                    doc.offeringReviewStatus === 'PENDING_REVIEWER' && (
                      <>
                        <Button
                          variant='normal'
                          disabled={loading}
                          onClick={() => setRejectionReason('')}
                        >
                          Reject
                        </Button>
                        <Button
                          variant='primary'
                          disabled={loading}
                          onClick={() =>
                            token &&
                            callApi(
                              new ApproveOfferingReviewCommand({
                                offeringReviewId,
                              }),
                              token
                            )
                              .then(() =>
                                setNotice({
                                  title: 'Success',
                                  body: 'Offering approved',
                                  callback: () =>
                                    (location.hash =
                                      '#/Moniker/offerings/' + doc?.offeringId),
                                })
                              )
                              .catch(apiFailed)
                          }
                        >
                          Approve
                        </Button>
                      </>
                    )}
                </>
              )}
              {token &&
                reviewPerms?.includes('CAN_DELETE') &&
                'offeringReviewStatus' in doc &&
                !['CANCELLED', 'APPROVED'].includes(doc.offeringReviewStatus) &&
                currentUser?.permissions.WRITE?.includes('OFFERING_REVIEW') && (
                  <Button
                    variant='normal'
                    disabled={loading}
                    onClick={() =>
                      token &&
                      callApi(
                        new CancelOfferingReviewCommand({
                          offeringReviewId,
                        }),
                        token
                      )
                        .then(() =>
                          setNotice({
                            title: 'Success',
                            body: 'Offering successfully deleted.',
                            callback: () => window.location.reload(),
                          })
                        )
                        .catch(apiFailed)
                    }
                  >
                    Delete draft
                  </Button>
                )}
            </SpaceBetween>
          ) : offeringId ? (
            <SpaceBetween direction='horizontal' size='s'>
              {/* {oldOffering?.longName &&
                oldOffering?.shortName &&
                entities &&
                !entities.length &&
                currentUser?.permissions.WRITE?.includes('OFFERING_REVIEW') && (
                  <Button onClick={() => setShowEntityForm(true)}>
                    Create entities
                  </Button>
                )} */}
              {currentUser?.permissions.WRITE?.includes('OFFERING_REVIEW') &&
                drafts &&
                !drafts.length && (
                  <Button
                    href={`#/Moniker/offerings/${encodeURIComponent(
                      offeringId
                    )}/edit?context=editOffering`}
                  >
                    Edit offering
                  </Button>
                )}
              {doc?.userPermissions.includes('CAN_UPDATE_LOC_INFO') && (
                <Button
                  href={`#/Moniker/offerings/${encodeURIComponent(
                    offeringId
                  )}/edit?context=editLoc`}
                >
                  Edit loc info
                </Button>
              )}
            </SpaceBetween>
          ) : (
            'No offering or draft selected'
          )
        }
      >
        <SpaceBetween size='m' direction='vertical'>
          <SpaceBetween
            direction='horizontal'
            size='m'
            className={formStyles.columns}
          >
            <SpaceBetween
              direction='vertical'
              size='m'
              className={formStyles.column}
            >
              <OfferingNameField
                disabled={true}
                value={doc?.offeringName ?? ''}
                old={oldOffering?.offeringName}
              />
              <PrefixRequiredField
                disabled={true}
                value={doc?.prefixRequired}
                old={oldOffering?.prefixRequired}
              />
              <RipServiceIdField
                disabled={true}
                value={doc?.ripServiceId ?? ''}
                old={oldOffering?.ripServiceId}
              />
              <BrandPrefixField
                disabled={true}
                value={doc?.brandPrefix}
                old={oldOffering?.brandPrefix}
              />
              <LaunchStatusField
                disabled={true}
                value={doc?.launchStatus}
                old={oldOffering?.launchStatus}
              />
              <TypeField
                disabled={true}
                value={doc?.type}
                old={oldOffering?.type}
              />
              {doc && 'locGuidanceRequired' in doc && (
                <LocGuidanceRequiredField
                  disabled={true}
                  value={doc.locGuidanceRequired ?? false}
                />
              )}
              <LocalizationNotesField
                disabled={true}
                value={doc?.localizationNotes ?? ''}
              />
              <LocalizationGuidanceField
                disabled={true}
                value={doc?.localizationGuidance}
              />
              <LocalizationWordsField
                disabled={true}
                value={doc?.localizationWords}
              />
              <GeneralNotesField
                disabled={true}
                value={doc?.generalNotes ?? ''}
                old={oldOffering?.generalNotes}
              />
              <ResourceExamplesField
                disabled={true}
                value={doc?.resourceExamples ?? ''}
                old={oldOffering?.resourceExamples}
              />
              <DocsCategoriesField
                disabled={true}
                value={doc?.docsCategories ?? []}
                old={oldOffering?.docsCategories}
              />
              {doc?.needEntity && doc?.displayOnHomepage && (
                <PromoteCategoriesField
                  disabled={true}
                  value={doc.promotedCategories ?? []}
                  old={oldOffering?.promotedCategories}
                  availableOptions={doc.promotedCategories ?? []}
                  onChange={() => {}}
                />
              )}
              <MarketingCategoriesField
                disabled={true}
                value={doc?.marketingCategories ?? []}
                old={oldOffering?.marketingCategories}
              />
              <LongNameField
                disabled={true}
                value={
                  doc?.longName ? delocalizeRegionMap(doc.longName) : undefined
                }
                old={
                  oldOffering?.longName
                    ? delocalizeRegionMap(oldOffering.longName)
                    : undefined
                }
              />
              <ExtraLongNameField
                disabled={true}
                value={
                  doc?.extraLongName
                    ? delocalizeRegionMap(doc.extraLongName)
                    : undefined
                }
                old={
                  oldOffering?.extraLongName
                    ? delocalizeRegionMap(oldOffering.extraLongName)
                    : undefined
                }
              />
            </SpaceBetween>
            <SpaceBetween
              direction={'vertical'}
              size='m'
              className={formStyles.column}
            >
              {doc && 'reviewerNotes' in doc && (
                <FormField label='Reject reason'>
                  <Container variant='default' className={formStyles.bad}>
                    {doc.reviewerNotes}
                  </Container>
                </FormField>
              )}
              <ShortNameField
                disabled={true}
                value={
                  doc?.shortName
                    ? delocalizeRegionMap(doc.shortName)
                    : undefined
                }
                old={
                  oldOffering?.shortName
                    ? delocalizeRegionMap(oldOffering.shortName)
                    : undefined
                }
              />
              <ExtraShortNameField
                disabled={true}
                value={
                  doc?.extraShortName
                    ? delocalizeRegionMap(doc.extraShortName)
                    : undefined
                }
                old={
                  oldOffering?.extraShortName
                    ? delocalizeRegionMap(oldOffering.extraShortName)
                    : undefined
                }
              />
              <DoNotUseNameField
                disabled={true}
                value={doc?.doNotUseName ?? ''}
                old={oldOffering?.doNotUseName}
              />
              <DeprecatedNameField
                disabled={true}
                value={doc?.deprecatedName ?? ''}
                old={oldOffering?.deprecatedName}
              />
              <ShortDescriptionField
                disabled={true}
                value={doc?.shortDescription ?? ''}
                old={oldOffering?.shortDescription}
              />

              {/* <LocSubscribersListField
              value={locSubscribersList}
              onChange={setLocSubscribersList}
              onValidate={setLocSubscribersListValid}
            /> */}
              <FormField label='Need entity'>
                <RadioGroup
                  // onChange={({ detail }) => setNeedEntity(detail.value === 'yes')}
                  value={doc?.needEntity ? 'yes' : 'no'}
                  items={[
                    { value: 'yes', label: 'Yes', disabled: true },
                    { value: 'no', label: 'No', disabled: true },
                  ]}
                />
              </FormField>
              {doc?.needEntity && (
                <>
                  <SpaceBetween
                    direction='vertical'
                    size='m'
                    className={formStyles.column}
                  >
                    <ShortNameEntityIdField
                      disabled={true}
                      value={doc?.shortNameEntityId ?? ''}
                      old={oldOffering?.shortNameEntityId}
                    />
                    <LongNameEntityIdField
                      disabled={true}
                      value={doc?.longNameEntityId ?? ''}
                      old={oldOffering?.longNameEntityId}
                    />
                  </SpaceBetween>
                  <FormField label='Display on home page'>
                    <RadioGroup
                      value={doc?.displayOnHomepage ? 'yes' : 'no'}
                      items={[
                        { value: 'yes', label: 'Yes', disabled: true },
                        { value: 'no', label: 'No', disabled: true },
                      ]}
                    />
                  </FormField>
                </>
              )}
              {doc?.needEntity && doc?.displayOnHomepage && (
                <>
                  <HomepageTitleField
                    disabled={true}
                    value={doc.title ?? ''}
                    old={oldOffering?.title}
                  />
                  <HomepageLinkField
                    disabled={true}
                    value={doc.homepageLink ?? ''}
                    old={oldOffering?.homepageLink}
                  />
                  <RegionsField
                    disabled={true}
                    value={doc.regions ?? []}
                    old={oldOffering?.regions}
                  />
                </>
              )}
            </SpaceBetween>
          </SpaceBetween>
          {(offeringId ?? doc?.offeringId) && (
            <FormField label='Entities'>
              {entities &&
                (entities.length ? (
                  <Entable entities={entities} />
                ) : doc?.longName &&
                  doc?.shortName &&
                  doc?.needEntity &&
                  currentUser?.permissions.WRITE?.includes(
                    'OFFERING_REVIEW'
                  ) ? (
                  <Button onClick={() => setShowEntityForm(true)}>
                    Create entities
                  </Button>
                ) : (
                  <Empty />
                ))}
            </FormField>
          )}
        </SpaceBetween>
      </Form>
      <Modal
        onDismiss={() => setRejectionReason(undefined)}
        visible={typeof rejectionReason === 'string'}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => setRejectionReason(undefined)}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={badRejection}
                onClick={() => {
                  if (
                    !(
                      token &&
                      offeringReviewId &&
                      typeof rejectionReason === 'string'
                    )
                  )
                    throw 'Fail';
                  callApi(
                    new RejectOfferingReviewCommand({
                      offeringReviewId,
                      additionalNotes: rejectionReason,
                    }),
                    token
                  )
                    .then(() =>
                      setNotice({
                        title: 'Success',
                        body: 'Offering draft rejected',
                        callback: () => location.reload(),
                      })
                    )
                    .catch(apiFailed);
                  setRejectionReason(undefined);
                }}
              >
                Reject
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Reject draft'
      >
        <FormField
          label='Rejection reason'
          description='Briefly explain what the requester needs to change'
        >
          <Input
            invalid={badRejection}
            value={rejectionReason ?? ''}
            onChange={(e) => setRejectionReason(e.detail.value)}
          />
        </FormField>
      </Modal>
      <Modal
        onDismiss={noticeCallback}
        header={<Box variant='h2'>{notice?.title}</Box>}
        visible={Boolean(notice)}
        size='small'
        footer={
          <SpaceBetween size='m' direction='horizontal' alignItems='end'>
            <Button variant='primary' onClick={noticeCallback}>
              OK
            </Button>
          </SpaceBetween>
        }
      >
        {notice?.body}
      </Modal>
      <Modal
        onDismiss={() => setShowEntityForm(false)}
        visible={showEntityForm}
        size='medium'
      >
        {<CreateEntitiesForm onClose={() => setShowEntityForm(false)} />}
      </Modal>
    </Container>
  );
};
