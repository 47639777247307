import {
  BrandPrefix,
  CreateOfferingReviewCommand,
  DataSource,
  DocumentationCategory,
  GetOfferingCommand,
  GetOfferingReviewCommand,
  LocalizationGuidance,
  LocalizationWords,
  MarketingCategory,
  Offering,
  OfferingReview,
  OfferingType,
  RegionalNameInformationInText,
  UpdateOfferingReviewCommand,
  UpdateOfferingReviewLocalizationInfoCommand,
  UpdateOfferingLocalizationInfoCommand,
  Partition,
  CreateOfferingPayload,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import formStyles from '../Form.module.sass';
import {
  Box,
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  SpaceBetween,
  FormField,
} from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import {
  BrandPrefixField,
  DeprecatedNameField,
  DocsCategoriesField,
  DoNotUseNameField,
  ExtraLongNameField,
  ExtraShortNameField,
  GeneralNotesField,
  LocalizationGuidanceField,
  LocalizationNotesField,
  LongNameField,
  MarketingCategoriesField,
  OfferingNameField,
  PrefixRequiredField,
  ResourceExamplesField,
  RipServiceIdField,
  ShortDescriptionField,
  ShortNameField,
  TypeField,
  LocSubscribersField,
  LocalizationWordsField,
  HomepageTitleField,
  HomepageLinkField,
  PromoteCategoriesField,
  LongNameEntityIdField,
  ShortNameEntityIdField,
} from '../fields';

import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';
import {
  delocalizeAllRegionMaps,
  delocalizeRegionMap,
} from '../delocalizeRegionMap';
import { diff } from 'deep-object-diff';
import { NoTokenError } from '../../../errors';
import { RegionsField } from '../fields';

export const CreateForm = (): ReactElement => {
  const offeringReviewId = /^#\/Moniker\/drafts\/([^/]+)\/edit(-loc)?\/?$/.exec(
    window.location.hash
  )?.[1];
  const rawOfferingId =
    /^#\/Moniker\/offerings\/([^/]+)\/edit(?:\?context=.*)?$/.exec(
      window.location.hash
    )?.[1];

  const offeringId = rawOfferingId
    ? decodeURIComponent(rawOfferingId)
    : undefined;
  const editingLoc = /\/edit-loc\/?$/.test(window.location.hash);

  const context =
    /^#\/Moniker\/offerings\/([^/]+)\/edit\?context=([^&]+)$/.exec(
      window.location.hash
    )?.[2];
  const isEditLocInfo = context === 'editLoc';
  console.log('offering draft', offeringReviewId);
  const [oldReview, setOldReview] = useState<OfferingReview>();
  const [oldReviewQueried, setOldReviewQueried] = useState(false);
  const [notice, setNotice] = useState<{
    title: string;
    body: string;
    callback?: () => void;
  }>();
  const [loading, setLoading] = useState(false);
  const { token } = useAuthState();
  const [valid, setValid] = useState(false);
  const [offeringName, setOfferingName] = useState('');
  const [prefixRequired, setPrefixRequired] = useState<boolean>();
  const [brandPrefix, setBrandPrefix] = useState<BrandPrefix>();
  const [shortName, setShortName] = useState<RegionalNameInformationInText>();
  const [extraShortName, setExtraShortName] =
    useState<RegionalNameInformationInText>();
  const [longName, setLongName] = useState<RegionalNameInformationInText>();
  const [extraLongName, setExtraLongName] =
    useState<RegionalNameInformationInText>();
  const [ripServiceId, setRipServiceId] = useState('');
  const [shortDescription, setShortDescription] = useState<string>('');
  const [doNotUseName, setDoNotUseName] = useState('');
  const [deprecatedName, setDeprecatedName] = useState('');
  const [generalNotes, setGeneralNotes] = useState('');
  const [resourceExamples, setResourceExamples] = useState('');
  const [docsCategories, setDocsCategories] = useState<DocumentationCategory[]>(
    []
  );

  const [promoteCategories, setPromoteCategories] = useState<
    DocumentationCategory[]
  >([]);

  const handlePromoteCategoriesChange = (
    selectedCategories: DocumentationCategory[]
  ) => {
    setPromoteCategories(selectedCategories);
  };

  const [shortNameEntityId, setShortNameEntityId] = useState('');
  const [longNameEntityId, setLongNameEntityId] = useState('');
  const [longNameEntityIdValid, setLongNameEntityIdValid] = useState(true);
  const [shortNameEntityIdValid, setShortNameEntityIdValid] = useState(true);
  const [title, setHomepageTitle] = useState('');
  const [homepageTitleValid, setHomepageTitleValid] = useState(true);
  const [homepageLink, setHomepageLink] = useState('');
  const [homepageLinkValid, setHomepageLinkValid] = useState(true);
  const [regions, setRegions] = useState<Partition[]>([]);
  const [regionsValid, setRegionsValid] = useState(true);

  const [marketingCategories, setMarketingCategories] = useState<
    MarketingCategory[]
  >([]);
  const [type, setType] = useState<OfferingType>();
  const [locSubscribersList, setLocSubscribersList] = useState<string[]>([]);

  const [offeringNameValid, setOfferingNameValid] = useState(false);
  const [prefixRequiredValid, setPrefixRequiredValid] = useState(false);
  const [brandPrefixValid, setBrandPrefixValid] = useState(false);
  const [shortNameValid, setShortNameValid] = useState(true);
  const [extraShortNameValid, setExtraShortNameValid] = useState(true);
  const [longNameValid, setLongNameValid] = useState(true);
  const [extraLongNameValid, setExtraLongNameValid] = useState(true);
  const [ripServiceIdValid, setRipServiceIdValid] = useState(false);
  const [shortDescriptionValid, setShortDescriptionValid] = useState(true);

  const [doNotUseNameValid, setDoNotUseNameValid] = useState(true);
  const [deprecatedNameValid, setDeprecatedNameValid] = useState(true);
  const [generalNotesValid, setGeneralNotesValid] = useState(true);
  const [resourceExamplesValid, setResourceExamplesValid] = useState(true);
  const [typeValid, setTypeValid] = useState(false);
  const [locSubscribersListValid, setLocSubscribersListValid] = useState(true);
  const [guidance, setGuidance] = useState<LocalizationGuidance>();
  const [notes, setNotes] = useState<string>();
  const [words, setWords] = useState<LocalizationWords>();
  const [wordsValid, setWordsValid] = useState(false);

  const [oldOffering, setOldOffering] = useState<Offering>();
  const [oldOfferingQueried, setOldOfferingQueried] = useState(false);
  const [canEditLoc, setCanEditLoc] = useState(false);
  const [canEditNonloc, setCanEditNonloc] = useState(false);
  const [needEntity, setNeedEntity] = useState(false);
  const [displayOnHomepage, setDisplayOnHomepage] = useState(false);
  const [warning, setWarning] = useState<string>('');

  const handleDisplayOnHomepageChange = ({
    detail,
  }: {
    detail: { value: string };
  }) => {
    if (!needEntity && detail.value === 'yes') {
      setWarning(
        'To display on home page need entity has to be selected to yes'
      );
    } else {
      setWarning('');
      setDisplayOnHomepage(detail.value === 'yes');
    }
  };

  useEffect(() => {
    setWarning('');
    if (!needEntity) {
      setDisplayOnHomepage(false);
    }
  }, [needEntity]);

  const fields = [
    offeringNameValid,
    prefixRequiredValid,
    brandPrefixValid,
    shortNameValid,
    extraShortNameValid,
    longNameValid,
    extraLongNameValid,
    ripServiceIdValid,
    shortDescriptionValid,
    doNotUseNameValid,
    deprecatedNameValid,
    generalNotesValid,
    resourceExamplesValid,
    typeValid,
    locSubscribersListValid,
    longNameEntityIdValid,
    shortNameEntityIdValid,
    homepageTitleValid,
    homepageLinkValid,
    regionsValid,
  ];
  const preloadFrom = (o: Offering | OfferingReview) => {
    if (o.userPermissions && isEditLocInfo) {
      setCanEditLoc(o.userPermissions.includes('CAN_UPDATE_LOC_INFO'));
    }
    if ('offeringReviewStatus' in o) {
      setCanEditLoc(
        o.offeringReviewStatus === 'PENDING_LOC_REVIEW' &&
          o.userPermissions.includes('CAN_UPDATE_LOC_INFO')
      );
      setCanEditNonloc(
        o.userPermissions.includes('CAN_UPDATE_ALL_EXCEPT_LOC_INFO')
      );
    }
    setOfferingName(o.offeringName ?? '');
    setBrandPrefix(o.brandPrefix);
    setPrefixRequired(o.prefixRequired);
    setShortName(o.shortName && delocalizeRegionMap(o.shortName));
    setLongName(o.longName && delocalizeRegionMap(o.longName));
    setExtraShortName(
      o.extraShortName && delocalizeRegionMap(o.extraShortName)
    );
    setExtraLongName(o.extraLongName && delocalizeRegionMap(o.extraLongName));
    setRipServiceId(o.ripServiceId ?? '');
    setShortDescription(o.shortDescription ?? '');
    setDoNotUseName(o.doNotUseName ?? '');
    setDeprecatedName(o.deprecatedName ?? '');
    setGeneralNotes(o.generalNotes ?? '');
    setResourceExamples(o.resourceExamples ?? '');
    setDocsCategories(o.docsCategories ?? []);
    setPromoteCategories(o.promotedCategories ?? []);
    setRegions(o.regions ?? []);
    setShortNameEntityId(o.shortNameEntityId ?? '');
    setLongNameEntityId(o.longNameEntityId ?? '');
    setNeedEntity(o.needEntity);
    setDisplayOnHomepage(o.displayOnHomepage);
    setHomepageTitle(o.title ?? '');
    setHomepageLink(o.homepageLink ?? '');
    setRegions(o.regions ?? []);
    setMarketingCategories(o.marketingCategories ?? []);
    setType(o.type);
    setWords(o.localizationWords);
    setNotes(o.localizationNotes);
    setGuidance(o.localizationGuidance);
    if ('locSubscribersList' in o) {
      setLocSubscribersList(o.locSubscribersList ?? []);
    }
  };
  useEffect(() => {
    if (!offeringReviewId || oldReview || oldReviewQueried || !token) return;
    setOldReviewQueried(true);
    callApi(new GetOfferingReviewCommand({ offeringReviewId }), token)
      .then((e) => {
        const o = e.offeringReview;
        if (!o) throw 'API success but no offering draft returned';
        setOldReview(o);
      })
      .catch((e) => alert(String(e)));
  }, [offeringReviewId, oldReview, oldReviewQueried]);
  useEffect(() => {
    if (!offeringId || oldOffering || oldOfferingQueried || !token) return;
    setOldOfferingQueried(true);
    callApi(new GetOfferingCommand({ offeringId }), token)
      .then((e) => {
        const o = e.offering;
        if (!o) throw 'API success but no offering returned';
        setOldOffering(o);
      })
      .catch((e) => alert(String(e)));
  }, [offeringId, oldOffering, oldOfferingQueried]);
  useEffect(() => oldReview && preloadFrom(oldReview), [oldReview]);
  useEffect(() => oldOffering && preloadFrom(oldOffering), [oldOffering]);

  useEffect(() => {
    setValid(fields.every((f) => f));
  }, fields);
  const failback = (res: unknown) =>
    setNotice({ title: 'Submission error', body: String(res) });
  const stripUndefined = <T extends Record<string, unknown>>(o: T) =>
    Object.fromEntries(
      Object.entries(o).filter(
        ([, v]) =>
          typeof v !== 'undefined' &&
          v !== '' &&
          (!Array.isArray(v) || v.length)
      )
    );
  const buildUnsafeForm = () => {
    return {
      offeringName,
      prefixRequired: prefixRequired ?? false,
      brandPrefix,
      shortName,
      extraLongName,
      extraShortName,
      longName,
      ripServiceId: ripServiceId.length ? ripServiceId : undefined,
      shortDescription,
      doNotUseName,
      deprecatedName,
      generalNotes,
      resourceExamples,
      docsCategories,
      marketingCategories,
      type,
      // locGuidanceRequired,
      locSubscribersList,
    };
  };
  const buildForm = () => {
    if (!extraLongName) throw 'Missing ELN';

    const docsCategoryConfig: Record<string, boolean> = docsCategories.reduce(
      (acc, category) => {
        const isPromoted = promoteCategories.some(
          (promoteCategory) => promoteCategory.id === category.id
        );
        acc[category.id] = isPromoted;
        return acc;
      },
      {} as Record<string, boolean>
    );
    setLoading(true);

    const result: CreateOfferingPayload = {
      offeringName,
      prefixRequired: prefixRequired ?? false,
      needEntity,
      displayOnHomepage,
      dataSource: DataSource.USER_INPUT,
      brandPrefix,
      doNotUseName,
      deprecatedName,
      resourceExamples,
      marketingCategories,
      type,
      ripServiceId: ripServiceId.length ? ripServiceId : undefined,
      shortName,
      extraLongName,
      extraShortName,
      longName,
      docsCategories: docsCategoryConfig,
      generalNotes,
      locGuidanceRequired: true,
      locSubscribersList,
      ...(shortDescription && { shortDescription }),
      ...(needEntity && longNameEntityId && { longNameEntityId }),
      ...(needEntity && shortNameEntityId && { shortNameEntityId }),
      ...(displayOnHomepage && regions && { regions }),
      ...(displayOnHomepage && homepageLink && { homepageLink }),
    };

    return result;
  };
  const [locValid, setLocValid] = useState(false);
  useEffect(
    () =>
      setLocValid(
        Boolean(
          guidance && (guidance !== 'Partial Translation OK' || wordsValid)
        )
      ),
    [guidance, wordsValid]
  );
  const updateOfferingLoc = () =>
    offeringId &&
    guidance &&
    token &&
    callApi(
      new UpdateOfferingLocalizationInfoCommand({
        offeringId,
        guidance,
        notes,
        words: guidance === 'Partial Translation OK' ? words : undefined,
      }),
      token
    )
      .then(() =>
        setNotice({
          title: 'Success!',
          body: 'Offering Loc updated.',
          callback: () => (location.hash = `/Moniker/offerings/${offeringId}`),
        })
      )
      .catch(failback);
  const updateOfferingReviewLoc = () =>
    offeringReviewId &&
    oldReview &&
    guidance &&
    token &&
    callApi(
      new UpdateOfferingReviewLocalizationInfoCommand({
        offeringReviewId,
        guidance,
        notes,
        words: guidance === 'Partial Translation OK' ? words : undefined,
        ...(shortDescription && { shortDescription }),
      }),
      token
    )
      .then(() =>
        setNotice({
          title: 'Success!',
          body: 'Offering draft updated.',
          callback: () =>
            (location.hash = `/Moniker/drafts/${offeringReviewId}`),
        })
      )
      .catch(failback);
  const updateOfferingReview = () => {
    setLoading(true);
    offeringReviewId &&
      oldReview &&
      token &&
      callApi(
        new UpdateOfferingReviewCommand({
          offeringName,
          prefixRequired: prefixRequired ?? false,
          brandPrefix,
          shortName,
          extraLongName,
          extraShortName,
          longName,
          ripServiceId: ripServiceId.length ? ripServiceId : undefined,
          ...(shortDescription && { shortDescription }),
          doNotUseName,
          deprecatedName,
          generalNotes,
          resourceExamples,
          marketingCategories,
          type,
          locGuidanceRequired: true,
          locSubscribersList,
          offeringReviewId,
          dataSource: DataSource.USER_INPUT,
          needEntity,
          displayOnHomepage,
        }),
        token
      )
        .then(() =>
          setNotice({
            title: 'Success!',
            body: 'Offering draft updated.',
            callback: () =>
              (location.hash = `/Moniker/drafts/${offeringReviewId}`),
          })
        )
        .catch(failback);
  };
  const createUpdateOfferingReview = () =>
    offeringId &&
    oldOffering &&
    token &&
    callApi(
      new CreateOfferingReviewCommand({
        update: { ...buildForm(), offeringId },
      }),
      token
    )
      .then((res) =>
        setNotice({
          title: 'Success!',
          body: 'Offering update submitted for review.',
          callback: () =>
            (location.hash = `/Moniker/drafts/${res.offeringReviewId}`),
        })
      )
      .catch(failback)
      .finally(() => setLoading(false));
  const createCreateOfferingReview = () => {
    if (!token) throw new NoTokenError();
    void callApi(
      new CreateOfferingReviewCommand({
        create: buildForm(),
      }),
      token
    )
      .then((res) => {
        setNotice({
          title: 'Success!',
          body: `Offering submitted to Loc team for review.`,
          callback: () =>
            (location.hash = `/Moniker/drafts/${res.offeringReviewId}`),
        });
      })
      .catch(failback)
      .finally(() => setLoading(false));
  };
  const noticeCallback = () => {
    setNotice(undefined);
    notice?.callback?.();
  };
  const ol = stripUndefined(delocalizeAllRegionMaps(oldOffering ?? {}));
  delete ol.offeringStatus;
  delete ol.offeringId;
  delete ol.localizationGuidance;
  delete ol.localizationWords;
  delete ol.localizationNotes;
  const ne = stripUndefined(buildUnsafeForm());
  const difference = diff(ol, ne);
  const different = Object.keys(difference).length > 0;
  return (
    <Container>
      <Form
        variant='full-page'
        className={formStyles.Form}
        header={
          <Box variant='h2'>
            {oldReview
              ? editingLoc
                ? `Update loc info for a ${oldReview.offeringName} draft`
                : `Update offering draft for ${oldReview.offeringName}`
              : oldOffering
              ? isEditLocInfo
                ? `Update loc info for ${oldOffering.offeringName}`
                : `Update offering ${oldOffering.offeringName}`
              : 'Create Offering'}
          </Box>
        }
        actions={
          <SpaceBetween size='m' direction='horizontal'>
            <Button
              variant='normal'
              onClick={() =>
                (location.hash = location.hash.includes('/drafts/')
                  ? '/Moniker/drafts'
                  : '/Moniker/offerings')
              }
            >
              Cancel
            </Button>
            {offeringReviewId || oldOffering ? (
              oldReview ? (
                editingLoc ? (
                  <Button
                    variant='primary'
                    disabled={!locValid || loading || !canEditLoc}
                    onClick={updateOfferingReviewLoc}
                  >
                    Update loc info
                  </Button>
                ) : (
                  <Button
                    variant='primary'
                    disabled={!valid || loading || !canEditNonloc}
                    onClick={updateOfferingReview}
                  >
                    Update draft
                  </Button>
                )
              ) : oldOffering && canEditLoc ? (
                <Button
                  variant='primary'
                  disabled={
                    !locValid || loading || !canEditLoc || !isEditLocInfo
                  }
                  onClick={updateOfferingLoc}
                >
                  Update loc info
                </Button>
              ) : (
                oldOffering && (
                  <Button
                    variant='primary'
                    disabled={!valid || loading || !different}
                    onClick={createUpdateOfferingReview}
                  >
                    Send to Loc
                  </Button>
                )
              )
            ) : (
              <Button
                variant='primary'
                disabled={!valid || loading}
                onClick={createCreateOfferingReview}
              >
                Send to Loc
              </Button>
            )}
          </SpaceBetween>
        }
      >
        <SpaceBetween
          direction='horizontal'
          size='m'
          className={formStyles.columns}
        >
          <SpaceBetween
            direction='vertical'
            size='m'
            className={formStyles.column}
          >
            <OfferingNameField
              required={true}
              onChange={setOfferingName}
              value={offeringName}
              onValidate={setOfferingNameValid}
              disabled={canEditLoc}
            />
            <PrefixRequiredField
              required={true}
              onChange={setPrefixRequired}
              value={prefixRequired}
              onValidate={setPrefixRequiredValid}
              disabled={canEditLoc}
            />
            <RipServiceIdField
              value={ripServiceId}
              onChange={setRipServiceId}
              onValidate={setRipServiceIdValid}
              disabled={canEditLoc}
              old={oldOffering?.offeringId ?? ripServiceId}
            />
            <BrandPrefixField
              value={brandPrefix}
              onChange={setBrandPrefix}
              onValidate={setBrandPrefixValid}
              disabled={canEditLoc}
            />
            <TypeField
              value={type}
              onChange={setType}
              onValidate={setTypeValid}
              disabled={canEditLoc}
            />
            <LocSubscribersField
              value={locSubscribersList}
              onChange={setLocSubscribersList}
              onValidate={setLocSubscribersListValid}
            />
            <GeneralNotesField
              value={generalNotes}
              onChange={setGeneralNotes}
              onValidate={setGeneralNotesValid}
              disabled={canEditLoc}
            />
            <ResourceExamplesField
              value={resourceExamples}
              onChange={setResourceExamples}
              onValidate={setResourceExamplesValid}
              disabled={canEditLoc}
            />
            {needEntity && displayOnHomepage && (
              <DocsCategoriesField
                required={displayOnHomepage}
                value={docsCategories}
                onChange={setDocsCategories}
                disabled={canEditLoc}
              />
            )}
            {needEntity && displayOnHomepage && (
              <PromoteCategoriesField
                value={promoteCategories}
                disabled={canEditLoc}
                availableOptions={docsCategories}
                onChange={handlePromoteCategoriesChange}
              />
            )}
            <MarketingCategoriesField
              value={marketingCategories}
              onChange={setMarketingCategories}
              disabled={canEditLoc}
            />
            <ExtraLongNameField
              required={!offeringId}
              value={extraLongName}
              onChange={setExtraLongName}
              onValidate={setExtraLongNameValid}
              disabled={canEditLoc}
            />
            <LongNameField
              required={needEntity}
              value={longName}
              onChange={setLongName}
              onValidate={setLongNameValid}
              disabled={canEditLoc}
            />
          </SpaceBetween>
          <SpaceBetween
            direction={'vertical'}
            size='m'
            className={formStyles.column}
          >
            <ShortNameField
              required={needEntity}
              value={shortName}
              onChange={setShortName}
              onValidate={setShortNameValid}
              disabled={canEditLoc}
            />
            <ExtraShortNameField
              value={extraShortName}
              onChange={setExtraShortName}
              onValidate={setExtraShortNameValid}
              disabled={canEditLoc}
            />
            <DoNotUseNameField
              value={doNotUseName}
              onChange={setDoNotUseName}
              onValidate={setDoNotUseNameValid}
              disabled={canEditLoc}
            />
            <DeprecatedNameField
              value={deprecatedName}
              onChange={setDeprecatedName}
              onValidate={setDeprecatedNameValid}
              disabled={canEditLoc}
            />
            <ShortDescriptionField
              required={displayOnHomepage}
              value={shortDescription}
              onChange={setShortDescription}
              onValidate={setShortDescriptionValid}
            />
            <FormField label='Need entity'>
              <RadioGroup
                onChange={({ detail }) => setNeedEntity(detail.value === 'yes')}
                value={needEntity ? 'yes' : 'no'}
                items={[
                  { value: 'yes', label: 'Yes', disabled: canEditLoc },
                  { value: 'no', label: 'No', disabled: canEditLoc },
                ]}
              />
            </FormField>
            {needEntity && (
              <>
                <SpaceBetween
                  direction='vertical'
                  size='m'
                  className={formStyles.column}
                >
                  <ShortNameEntityIdField
                    required={needEntity}
                    value={shortNameEntityId}
                    onChange={setShortNameEntityId}
                    onValidate={setShortNameEntityIdValid}
                    disabled={canEditLoc}
                  />
                  <LongNameEntityIdField
                    required={needEntity}
                    value={longNameEntityId}
                    onChange={setLongNameEntityId}
                    onValidate={setLongNameEntityIdValid}
                    disabled={canEditLoc}
                  />
                </SpaceBetween>
              </>
            )}
            <FormField label='Display on home page' errorText={warning}>
              <RadioGroup
                onChange={handleDisplayOnHomepageChange}
                value={displayOnHomepage ? 'yes' : 'no'}
                items={[
                  { value: 'yes', label: 'Yes', disabled: canEditLoc },
                  { value: 'no', label: 'No', disabled: canEditLoc },
                ]}
              />
            </FormField>
            {needEntity && displayOnHomepage && (
              <>
                <HomepageTitleField
                  required={needEntity}
                  value={title}
                  onChange={setHomepageTitle}
                  onValidate={setHomepageTitleValid}
                  longNameEntityId={longNameEntityId}
                  disabled={true}
                />
                <HomepageLinkField
                  required={displayOnHomepage}
                  value={homepageLink}
                  onChange={setHomepageLink}
                  onValidate={setHomepageLinkValid}
                  disabled={canEditLoc}
                />
                <RegionsField
                  required={displayOnHomepage}
                  value={regions}
                  onValidate={setRegionsValid}
                  onChange={setRegions}
                  disabled={canEditLoc}
                />
              </>
            )}
            {(editingLoc || canEditLoc) && (
              <>
                <LocalizationGuidanceField
                  disabled={!canEditLoc}
                  value={guidance}
                  onChange={setGuidance}
                />
                <LocalizationNotesField
                  disabled={!canEditLoc}
                  value={notes ?? ''}
                  onChange={setNotes}
                />
                {guidance === 'Partial Translation OK' && (
                  <LocalizationWordsField
                    disabled={!canEditLoc}
                    value={words}
                    onChange={setWords}
                    onValidate={setWordsValid}
                  />
                )}
              </>
            )}
          </SpaceBetween>
        </SpaceBetween>
      </Form>
      <Modal
        onDismiss={noticeCallback}
        header={<Box variant='h2'>{notice?.title}</Box>}
        visible={Boolean(notice)}
        size='medium'
        footer={
          <SpaceBetween size='m' direction='horizontal' alignItems='end'>
            <Button variant='primary' onClick={noticeCallback}>
              OK
            </Button>
          </SpaceBetween>
        }
      >
        {notice?.body}
      </Modal>
    </Container>
  );
};
